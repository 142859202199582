import { MAIN_URL, WIN1_TG_URL, WIN2_TG_URL, WIN3_TG_URL, WIN5_TG_URL } from "../config";

const urlEnum = {
  MAIN_URL: MAIN_URL,
  LOTWIN1: WIN1_TG_URL,
  LOTWIN2: WIN2_TG_URL,
  LOTWIN3: WIN3_TG_URL,
  LOTWIN5: WIN5_TG_URL,
};

export default urlEnum;
