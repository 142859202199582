import { useEffect } from "react";
import "./App.css";
import { PRODUCTION_NAME_DEV } from "./config";
import Home from "./pages/home";

function App() {
  const initStyle = () => {
    let _vh = window.innerHeight * 0.01;
    window.document.documentElement.style.setProperty("--vh", `${_vh}px`);
    window.document.documentElement.style.setProperty("background", `#f5f5f5`);
  };

  const currentTheme = require(`./theme/${PRODUCTION_NAME_DEV}/theme.json`);
  const defaultTheme = require(`./theme/defaultTheme.json`);
  const finalTheme = { ...defaultTheme, ...currentTheme };
  const setTheme = () => {
    const r = document.querySelector(":root");
    const final = Object.entries(finalTheme);
    final.map((color) => {
      r.style.setProperty(color[0], color[1]);
    });
  };

  useEffect(() => {
    setTheme();
  }, [finalTheme]);
  useEffect(() => {
    initStyle();
  }, []);
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
