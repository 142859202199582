import { LinkItem } from "../../components";
import { PRODUCTION_NAME_DEV, PRODUCTION_NAME_DISPLAY } from "../../config";
import urlEnum from "../../enumerations/urlEnum";
import styles from "./style.module.scss";
const Home = () => {
  const LINK = [
    {
      id: "LOTWIN1",
      title: "Win Diamond",
      titleColor: "linear-gradient(180deg, #5685E1 0%, #A65AF3 50%, #EFC3FF 100%)",
    },
    {
      id: "LOTWIN2",
      title: "Win Platinum",
      titleColor: "linear-gradient(180deg, #CBE9FF 0%, #6DACD4 50%, #485458 100%)",
    },
    {
      id: "LOTWIN3",
      title: "Win Gold",
      titleColor: "linear-gradient(180deg, #FFD600 0%, #CEAD00 50%, #F90 100%)",
    },
    {
      id: "LOTWIN5",
      title: "Win Silver",
      titleColor: "linear-gradient(180deg, #898989 0%, #898989 50%, #898989 100%)",
    },
  ];

  const renderLinks = () => {
    return LINK.map((link) => <LinkItem key={link.id} item={link} />);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          className={styles.header}
          src={`/images/${PRODUCTION_NAME_DEV}/header.png`}
          alt={PRODUCTION_NAME_DISPLAY}
        />
        <div className={styles.innerContent}>
          <div className={styles.contentTitle}>
            <img className={styles.titleBG} src={"/images/title.png"} alt="description" />
            <span>Description</span>
          </div>
          <div className={styles.contentDesc}>
            <p>
              {`${PRODUCTION_NAME_DISPLAY} Game is the leading prediction-based gaming platform. The ${PRODUCTION_NAME_DISPLAY} Game app has
              a wide selection of prediction-based games like Win Game, T1Game, Jili Fishing, Jili
              Slots, SABA Sports, and the list goes on.`}
            </p>
            <p>
              {`Alongside their games, the ${PRODUCTION_NAME_DISPLAY} Game app has initiated a wide selection of programs
              that help the ${PRODUCTION_NAME_DISPLAY} Game users earn additional money from the ${PRODUCTION_NAME_DISPLAY} Game app.`}
            </p>
          </div>
          <div className={styles.linkContainer}>{renderLinks()}</div>
          <div
            className={styles.highlight}
            // style={{background:"url('../../images/${PRODUCTION_NAME_DEV}/bg1.png') no-repeat center / contain"}}
            style={{
              background: `url("/images/${PRODUCTION_NAME_DEV}/bg1.png") no-repeat center / contain`,
            }}
          >
            <div className={styles.highlightTitle}>{PRODUCTION_NAME_DISPLAY}</div>
            <div className={styles.highlightSubTitle}>The Best Money Earning</div>
            <div className={styles.highlightSubTitle}>Website In India !</div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <a href={urlEnum.MAIN_URL} className={styles.button}>
          START EARNING
        </a>
      </div>
    </div>
  );
};

export default Home;
