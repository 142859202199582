import urlEnum from "../../enumerations/urlEnum";
import styles from "./styles.module.scss";
const LinkItem = ({ item }) => {
  return (
    <div className={styles.linkItemContainer}>
      <img className={styles.listFrame} src={"/images/listFrame.svg"} alt="" />
      <div className={styles.tgButtonContainer}>
        <a href={urlEnum[item.id]}>
          <img src={"/images/tg.png"} alt={item.id} />
          <div className={styles.tgButton} href={urlEnum[item.id]}>
            <img src={"/images/click.png"} alt="click" />
            <span>Click to join</span>
          </div>
        </a>
      </div>
      <div className={styles.divider} />
      <div className={styles.descContainer}>
        <span className={styles.desc}>AI Prediction</span>
        <span
          className={styles.title}
          style={{
            background: item.titleColor,
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {item.title}
        </span>
        <span className={styles.desc2}>Stable trends will help you increase your profits.</span>
      </div>
      <div className={styles.imageContainer}>
        <a href={urlEnum.MAIN_URL}>
          <img src={`/images/${item.id}.png`} alt={item.title} />
        </a>
      </div>
    </div>
  );
};

export default LinkItem;
